import { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import { TextField } from "../../../../components";

export default function AudienceName({
  state,
  dispatch,
  editState,
  formikProps,
}) {
  const [input, setInput] = useState({});
  const [message, setMessage] = useState({});

  useEffect(() => {
    if (state["audience_group"]) {
      setInput((prev) => ({
        ...prev,
        audience_group: state["audience_group"],
      }));
    }
  }, [state]);

  const handleFieldChange = (value, name, key) => {
    dispatch({
      type: "UPDATE_FIELD",
      formikProps: formikProps,
      payload: { value, name, key },
    });
  };

  return (
    <Grid item xs={12}>
      <TextField
        required
        size="small"
        label="Audience Group Name"
        name="audience_group.value"
        placeholder="Audience Group Name"
        disabled={!editState}
        value={input?.audience_group?.value}
        onBlur={(e) => {
          if (input.audience_group?.value) {
            handleFieldChange(
              input.audience_group.value,
              "audience_group",
              "value"
            );
          }
        }}
        onChange={(e) => {
          if (e.target.value?.length < 3) {
            setMessage({
              audience_group: "Audience Group must be at least 3 characters",
            });
          } else {
            setMessage({});
          }
          setInput({ audience_group: { value: e.target.value } });
        }}
        error={message["audience_group"]}
        helperText={message["audience_group"]}
      />
    </Grid>
  );
}
