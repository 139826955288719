import { debounce } from "lodash";
import { Grid, Pagination } from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";

import EmptyList from "../misc/EmptyList";
import Search from "./brand list components/Search";
import { Loader, PageHeader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import BrandList from "./brand list components/BrandList";

export default function SuperAdminBrand() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [page, setPage] = useState(1);
  const [brands, setBrands] = useState();
  const [brandList, setBrandList] = useState([]);
  const [searchBrands, setSearchBrands] = useState();
  const [lastPage, setLastPage] = useState({ totalCount: 0, lastPage: false });

  useEffect(() => {
    loader.start();

    let url = "/getAllBrands" + `?page=${page}&limit=10`;

    if (brands) {
      url = url + "&brand_type=" + brands;
    }

    if (searchBrands) {
      url = url + "&search=" + encodeURIComponent(searchBrands);
    }

    axios({
      url: url,
    }).then((response) => {
      if (response.status) {
        if (response.data.parsedBrands.length > 0) {
          setBrandList(response.data.parsedBrands);

          setLastPage({
            totalCount: response.data.totalCount,
            lastPage: response.data.parsedBrands.length < 10,
          });
        } else {
          setBrandList(response.data);

          setLastPage({
            totalCount: response.data.totalCount,
            lastPage: true,
          });
        }

        loader.apiComplete();
      }
    });
  }, [loader, axios, brands, searchBrands, page]);

  const handleSearchDebounce = debounce(async (value) => {
    setSearchBrands(value);
  }, 300);

  function handlePagination(step, index) {
    setPage((prev) =>
      step === "index" ? index : prev + (step === "next" ? 1 : -1)
    );
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            pageName={"All Brands"}
            pageHeader={"View Users by brands"}
            icons={[
              // <Categorize
              //   categorize={categorize}
              //   setCategorize={setCategorize}
              //   brands={brands}
              //   setPage={setPage}
              //   setBrands={setBrands}
              //   setSearchBrands={setSearchBrands}
              // />,

              <Search
                handleSearchDebounce={handleSearchDebounce}
                setPage={setPage}
                setBrands={setBrands}
                setSearchBrands={setSearchBrands}
              />,
            ]}
          />
        </Grid>
      </Grid>

      <Loader height="75%">
        {brandList.length > 0 ? (
          <BrandList brandList={brandList} component={"users"} />
        ) : (
          <EmptyList title={"No new brand"} />
        )}
      </Loader>

      {!!lastPage.totalCount && (
        <Grid item container display={"flex"} justifyContent={"center"}>
          <Pagination
            count={Math.ceil(lastPage.totalCount / 10)}
            variant="outlined"
            color="secondary"
            page={page}
            onChange={(e, value) => handlePagination("index", value)}
          />
        </Grid>
      )}
    </Fragment>
  );
}
