import * as Yup from "yup";
import React, { Fragment, useState } from "react";
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  IconButton,
  Grid,
  Divider,
} from "@mui/material";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { HighlightOff } from "@mui/icons-material";

import { useAxios } from "../../../hooks";
import AudienceSizeForm from "./AudienceSizeForm";
import { alert } from "../../../redux/slices/alertSlice";
import { PrimaryLoadingButton } from "../../css components/Button";
import { ActiveTextEighteen, colors } from "../../css components/Style";

export default function AudienceSizeDialog({
  formikProps,
  audienceSize,
  audienceSizing,
  setAudienceSize,
  setAudienceSizing,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setAudienceSize({ state: false });
  };

  console.log(audienceSizing);

  return (
    <React.Fragment>
      <MUIDialog open={audienceSize.state} onClose={handleClose}>
        <Grid container spacing={2} padding={"8px 24px"}>
          <Grid item xs={6} alignSelf={"center"}>
            <ActiveTextEighteen color={colors.purple}>
              Set Audience Size
            </ActiveTextEighteen>
          </Grid>

          <Grid item xs={6} alignSelf={"center"} textAlign={"end"}>
            <IconButton>
              <HighlightOff onClick={() => handleClose()} />
            </IconButton>
          </Grid>
        </Grid>

        <Divider />

        <Formik
          enableReinitialize
          initialValues={audienceSizing}
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={(formData, { setSubmitting }) => {
            setLoading(true);
            axios({
              url: "/create/audienceSizing",
              method: "POST",
              disableRedirect: true,
              data: {
                brand_id: parseInt(formikProps.values.brandID),
                campaign_id: parseInt(formikProps.values.campaignID),
                audience_id: parseInt(formikProps.values.audienceID),
                audience_params: formData.audience_params,
                audience_size: formData.audience_size,
                audience_pricing: formData.audience_pricing,
              },
            }).then((response) => {
              if (response.status) {
                dispatch(
                  alert({
                    type: "success",
                    message: response.message.displayMessage,
                  })
                );
                setAudienceSizing(response.data);
                handleClose();
                setSubmitting(false);
              }
              setLoading(false);
            });
          }}
        >
          {(dialogFormik) => (
            <Fragment>
              <DialogContent style={{ padding: "0 24px" }}>
                <Grid container spacing={2}>
                  <AudienceSizeForm
                    audienceSizing={audienceSizing}
                    formikProps={dialogFormik}
                  />
                </Grid>
              </DialogContent>

              <DialogActions style={{ padding: "16px 24px 16px" }}>
                <PrimaryLoadingButton
                  variant="contained"
                  disabled={!dialogFormik.dirty || !dialogFormik.isValid}
                  loading={loading}
                  onClick={dialogFormik.handleSubmit}
                >
                  Save
                </PrimaryLoadingButton>
              </DialogActions>
            </Fragment>
          )}
        </Formik>
      </MUIDialog>
    </React.Fragment>
  );
}

const validationSchema = Yup.object().shape({
  audience_params: Yup.string().required("This is a required field"),
  audience_size: Yup.string().required("This is a required field"),
});
