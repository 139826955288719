import { Fragment, useEffect, useState } from "react";
import { DeleteOutline } from "@mui/icons-material";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";

import { colors } from "../../css components/Style";
import { ComponentHeader, Label, TextField } from "../../../components";

export default function FormThree({ state, dispatch, editState }) {
  const [message, setMessage] = useState({});
  const [inputValue, setInputValue] = useState({ additionalFields: [] });

  useEffect(() => {
    if (state["additionalFields"]) {
      setInputValue((prev) => ({
        ...prev,
        additionalFields: state["additionalFields"],
      }));
    }
  }, [state]);

  async function handleFieldChange(value, name, key) {
    if (inputValue.additionalFields.length > 0) {
      let index = 0;
      for await (let i of inputValue.additionalFields) {
        checkValidation(index);
        index++;
      }

      if (Object.keys(message).length === 0) {
        dispatch({
          type: "ADDITIONAL_FIELDS",
          payload: { value, name },
        });
      }
    }
  }

  function checkValidation(index) {
    if (
      !inputValue.additionalFields[index].key ||
      !inputValue.additionalFields[index].value
    ) {
      setMessage({
        ...message,
        [index]: "Please fill the values or delete the field.",
      });
    } else {
      let messages = { ...message };
      const updatedObj = Object.fromEntries(
        Object.entries(messages).filter(
          ([key]) => parseInt(key) !== parseInt(index)
        )
      );
      setMessage(updatedObj);
    }
  }

  return (
    <Fragment>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Additional Field"}
          subtitle={"Not able to find desired audience field? Add them."}
          buttons={[
            <Button
              onClick={handleAddFields}
              variant="contained"
              disabled={!editState}
            >
              Add Additional Field
            </Button>,
          ]}
        />
      </Grid>

      {inputValue.additionalFields.map((_, index) => (
        <Fragment>
          <Grid item xs={10}>
            <Label title={"Additional Field " + (index + 1)} />
          </Grid>

          <Grid item xs={5}>
            <TextField
              size="small"
              value={inputValue.additionalFields?.[index]?.key}
              disabled={!editState}
              name={"additionalFields.[" + index + "].key"}
              placeholder="key"
              label="Key"
              onBlur={(e) => {
                if (inputValue.additionalFields?.length > 0) {
                  handleFieldChange(
                    inputValue.additionalFields,
                    "additionalFields"
                  );
                }
              }}
              onChange={(e) => {
                setInputValue({
                  additionalFields: setAdditionalField(
                    inputValue.additionalFields
                  ),
                });

                function setAdditionalField(val) {
                  val[index] = {
                    ...val[index],
                    key: e.target.value,
                  };

                  return val;
                }

                checkValidation(index);
              }}
            />
          </Grid>

          <Grid item xs={5}>
            <TextField
              size="small"
              value={inputValue.additionalFields?.[index]?.value}
              disabled={!editState}
              name={"additionalFields.[" + index + "].value"}
              placeholder="Value"
              label="Value"
              onBlur={(e) => {
                if (inputValue.additionalFields?.length > 0) {
                  handleFieldChange(
                    inputValue.additionalFields,
                    "additionalFields"
                  );
                }
              }}
              onChange={(e) => {
                setInputValue({
                  additionalFields: setAdditionalField(
                    inputValue.additionalFields
                  ),
                });

                function setAdditionalField(val) {
                  val[index] = {
                    ...val[index],
                    value: e.target.value,
                  };

                  return val;
                }

                checkValidation(index);
              }}
            />
          </Grid>

          {editState && (
            <Grid item xs={2} alignSelf={"center"}>
              <Tooltip title={"Delete additional field" + (index + 1)}>
                <IconButton
                  style={{ color: colors.error }}
                  onClick={() => handleDelete(index)}
                >
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="caption" color={colors.error}>
              {message[index]}
            </Typography>
          </Grid>
        </Fragment>
      ))}
    </Fragment>
  );

  function handleDelete(index) {
    if (message[index]) {
      let messages = { ...message };
      const updatedObj = Object.fromEntries(
        Object.entries(messages).filter(
          ([key]) => parseInt(key) !== parseInt(index)
        )
      );
      setMessage(updatedObj);
    }
    let fields = [...inputValue.additionalFields];
    fields.splice(index, 1);
    let name = "additionalFields";

    setInputValue({ additionalFields: fields });
    dispatch({
      type: "ADDITIONAL_FIELDS",
      payload: { fields, name },
    });
  }

  function handleAddFields() {
    let addField = true;
    if (inputValue.additionalFields.length > 0) {
      for (let fields of inputValue.additionalFields) {
        if (!fields.key || !fields.value) {
          addField = false;
          setMessage({
            ...message,
            [inputValue.additionalFields.length - 1]:
              "Please fill the other fields first before adding another field.",
          });
        }
      }

      if (addField) {
        setInputValue({
          additionalFields: [
            ...inputValue.additionalFields,
            {
              key: "",
              value: "",
            },
          ],
        });
      }
    } else {
      setInputValue({
        additionalFields: [
          ...inputValue.additionalFields,
          {
            key: "",
            value: "",
          },
        ],
      });
    }
  }
}
