import { Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

import { Mandatory } from "../FormOne";
import { TextField } from "../../../../components";
import { colors } from "../../../css components/Style";
import { sentenceCase } from "change-case-all";

function Range({
  state,
  dispatch,
  field,
  editState,
  formikProps,
  setButtonDisable,
}) {
  const [input, setInput] = useState({});
  const [message, setMessage] = useState({});

  const handleFieldChange = (value, name, key) => {
    dispatch({ type: "RANGE_UPDATE", payload: { value, name, key } });
  };

  useEffect(() => {
    if (state[field.name]) {
      setInput((prev) => ({
        ...prev,
        [field.name]: state[field.name],
      }));
    }
  }, [state, field.name]);

  function handleValueValidation(num1, num2 = 0, field, value) {
    console.log(
      num1 > num2,
      parseInt(value) <= field.min_default_val,
      parseInt(value) >= field.max_default_val,
      value
    );
    if (
      num1 > num2 ||
      parseInt(value) <= field.min_default_val ||
      parseInt(value) >= field.max_default_val
    ) {
      setMessage({
        ...message,
        [field.name]: sentenceCase(
          field.label +
            " should be less than " +
            field.max_default_val +
            " & greater than " +
            field.min_default_val +
            " & min " +
            field.label +
            " should be greater than max " +
            field.label
        ),
      });
    } else {
      let messages = { ...message };
      const updatedObj = Object.fromEntries(
        Object.entries(messages).filter(([key]) => key !== field.name)
      );
      setMessage(updatedObj);
    }
  }

  return (
    <Fragment>
      <Grid item xs={6}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ paddingTop: "0px" }}>
            <Mandatory
              dispatch={dispatch}
              state={state}
              editState={editState}
              field={field}
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              value={input?.[field.name]?.value?.[field.options[0].value]}
              size="small"
              onBlur={(e) => {
                if (!!input[field.name]?.value?.[field.options[0].value]) {
                  handleFieldChange(
                    input[field.name]?.value?.[field.options[0].value],
                    field.name,
                    `${field.options[0].value}`
                  );
                }
              }}
              onChange={(e) => {
                const { value } = e.target;
                handleValueValidation(
                  value,
                  input?.[field.name]?.value?.[field.options[1].value],
                  field,
                  value
                );
                setInput((prev) => ({
                  ...prev,
                  [field.name]: {
                    ...input[field.name],
                    value: {
                      [field.options[0].value]: value,
                    },
                  },
                }));
              }}
              label={field.options[0].label}
              name={`${field.name}.value.${field.options[0].value}`}
              placeholder={field.options[0].label}
              disabled={!editState}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              size="small"
              onBlur={(e) => {
                if (input[field.name]?.value?.[field.options[1].value]) {
                  handleFieldChange(
                    input[field.name]?.value?.[field.options[1].value],
                    field.name,
                    `${field.options[1].value}`
                  );
                }
              }}
              onChange={(e) => {
                const { value } = e.target;

                handleValueValidation(
                  input?.[field.name]?.value?.[field.options[0].value],
                  value,
                  field,
                  value
                );

                setInput((prev) => ({
                  ...prev,
                  [field.name]: {
                    ...input[field.name],
                    value: {
                      [field.options[1].value]: e.target.value,
                    },
                  },
                }));
              }}
              value={input?.[field.name]?.value?.[field.options[1].value]}
              label={field.options[1].label}
              name={`${field.name}.value.${field.options[1].value}`}
              placeholder={field.options[1].label}
              disabled={!editState}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography color={colors.error} variant="caption">
              {message[field.name]}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Range;
