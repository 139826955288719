import { Fragment, useEffect, useState } from "react";
import { Grid } from "@mui/material";

import { Mandatory } from "../FormOne";
import { TextField } from "../../../../components";

export default function Input({
  dispatch,
  state,
  field,
  editState,
  formikProps,
  setButtonDisable,
}) {
  const [input, setInput] = useState({});
  const [message, setMessage] = useState({});

  useEffect(() => {
    if (state[field.name]) {
      setInput((prev) => ({
        ...prev,
        [field.name]: state[field.name],
      }));
    }
  }, [state, field.name]);

  const handleFieldChange = (value, name, key) => {
    dispatch({
      type: "UPDATE_FIELD",
      formikProps: formikProps,
      payload: { value, name, key },
    });
  };

  return (
    <Fragment>
      <Grid item xs={6}>
        <Mandatory
          state={state}
          editState={editState}
          field={field}
          formikProps={formikProps}
          dispatch={dispatch}
        />

        <TextField
          size="small"
          value={input[field.name]?.value}
          label={field.label}
          name={`${field.name}.value`}
          placeholder={field.label}
          disabled={!editState}
          onBlur={(e) => {
            if (!!input[field.name].value) {
              handleFieldChange(input[field.name].value, field.name, "value");
            }
          }}
          onChange={(e) => {
            setInput((prev) => ({
              ...prev,
              [field.name]: { value: e.target.value },
            }));
          }}
        />
      </Grid>
    </Fragment>
  );
}
