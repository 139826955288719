import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import FormOne from "./FormOne";
import {
  ButtonFooter,
  ComponentHeader,
  PageHeader,
  Paper,
} from "../../../components";
import EditButtons from "../../../components/EditButtons";
import { CancelButton } from "../../css components/Button";
import FloatingEditActionButtons from "../../../components/FloatingEditActionButton";
import FormThree from "./FormThree";
import { useState } from "react";

export default function FormDisplay({
  state,
  dispatch,
  fields,
  editState,
  setEditState,
  formikProps,
  component,
  handleClose,
}) {
  const navigate = useNavigate();
  const [buttonDisable, setButtonDisable] = useState(true);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {component !== "dialog" && (
          <PageHeader
            pageName={"Audience"}
            pageHeader={
              component === ""
                ? "Let's create audience group"
                : formikProps.values.audience_group?.value
            }
            buttons={[
              formikProps.values.isEditable ? (
                <EditButtons
                  editState={editState}
                  setEditState={setEditState}
                />
              ) : (
                <CancelButton variant="outlined" onClick={() => navigate(-1)}>
                  Back
                </CancelButton>
              ),
            ]}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        <Paper gridSpacing={6}>
          <Grid item xs={12}>
            <ComponentHeader
              title={"Audience"}
              subtitle={
                formikProps.values.ID
                  ? "Audience group details"
                  : "Let's create audience group"
              }
            />
          </Grid>

          <FormOne
            setButtonDisable={setButtonDisable}
            state={state}
            dispatch={dispatch}
            fields={fields}
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
          />

          <FormThree
            setButtonDisable={setButtonDisable}
            state={state}
            dispatch={dispatch}
            fields={fields}
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
          />
        </Paper>

        {formikProps.values.isEditable && !Boolean(handleClose) && (
          <FloatingEditActionButtons
            ID={formikProps.values.campaignID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
          />
        )}

        {editState && (
          <Grid item xs={12}>
            <ButtonFooter
              ID={formikProps.values.ID}
              editState={editState}
              setEditState={setEditState}
              formikProps={{
                ...formikProps,
                dirty: true,
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
