import { Formik } from "formik";
import { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";
import { useAxios, useAlert } from "../../../hooks";
import { objectFromFormData } from "./audienceObjects";

export default function Form({
  fields,
  formData,
  component,
  editState,
  sliderValue,
  campaignID,
  handleClose,
  setEditState,
  setSliderValue,
  dialogDetails,
  setAudienceCreated,
  campaignFormikProps,
}) {
  const axios = useAxios();
  var intialValue = formData;

  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatchUpdate = useDispatch();

  const memberDetails = useSelector((state) => state.memberDetails);

  const [state, dispatch] = useReducer(formReducer, intialValue);

  return (
    <Formik
      enableReinitialize
      initialValues={state}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        let url = "/brand/audience";
        let method = "POST";

        if (!!formData.ID) {
          url = "/brand/audience/" + formData.ID;
          method = "PUT";
        }

        axios({
          url: url,
          method: method,
          disableRedirect: true,
          data: objectFromFormData(state, memberDetails),
        })
          .then((res) => {
            if (res.status) {
              if (component !== "dialog") {
                if (!!formData.ID) {
                  setEditState(false);
                  dispatchUpdate(
                    alert({
                      message: "Audience updated successfully!",
                      type: "success",
                    })
                  );
                } else {
                  dispatchUpdate(
                    alert({
                      message: "Audience created successfully!",
                      type: "success",
                    })
                  );
                  navigate(pages.myCampaign.route);
                }
              } else {
                setAudienceCreated(res.data.audience_id);
                campaignFormikProps.setValues((prev) => ({
                  ...prev,
                  audienceID: res.data.audience_id,
                }));
                handleClose();
              }

              setSubmitting(false);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <FormDisplay
          state={state}
          dispatch={dispatch}
          fields={fields}
          sliderValue={sliderValue}
          setSliderValue={setSliderValue}
          handleClose={handleClose}
          component={component}
          formikProps={formikProps}
          editState={editState}
          setEditState={setEditState}
          campaignID={campaignID}
          dialogDetails={dialogDetails}
          setAudienceCreated={setAudienceCreated}
          campaignFormikProps={campaignFormikProps}
        />
      )}
    </Formik>
  );
}

const formReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FIELD":
      return {
        ...state,
        [action.payload.name]: {
          ...state[action?.payload?.name],
          [action.payload.key]: action.payload.value,
        },
      };
    case "MULTI_DROP_DOWN":
      return {
        ...state,
        [action.payload.name]: {
          ...state[action?.payload?.name],
          [action.payload.key]: action.payload.value,
        },
      };

    case "DATE_UPDATE_FIELD":
      return {
        ...state,
        [action.payload.name]: {
          ...state[action?.payload?.name],
          [action.payload.key]: action.payload.value,
        },
      };

    case "RANGE_UPDATE":
      return {
        ...state,
        [action.payload.name]: {
          ...state[action?.payload?.name],
          value: {
            ...state[action?.payload?.name]?.value,
            [action.payload.key]: action.payload.value,
          },
        },
      };

    case "CALCULATE_PERCENTAGE":
      let value = action.payload.value;
      if (action.payload.value === "on") {
        value = true;
      }

      if (action.payload.value === "off") {
        value = false;
      }

      return {
        ...state,
        [action.payload.name]: {
          ...state[action?.payload?.name],
          [action.payload.key]: value,
        },
      };

    case "ADDITIONAL_FIELDS":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };

    case "UPDATE_NUMBER_FIELD":
      return {
        ...state,
        [action.payload.name]: {
          ...state[action?.payload?.name],
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
};
