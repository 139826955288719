import { Fragment } from "react";
import { CgEye } from "react-icons/cg";
import { MdEdit } from "react-icons/md";
import { titleCase } from "change-case-all";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton, Tooltip } from "@mui/material";

import pages from "../../../constants/pages";
import { Paper, TextAvatar } from "../../../components";
import { HeadingEighteen, TextFourteenBlack } from "../../css components/Style";

let colors = JSON.parse(sessionStorage.getItem("siteColors"));

const Card = styled(Grid)`
  display: flex;
  gap: 20px;
`;

const OuterCard = styled(Paper)`
  display: flex;
  gap: 20px;
  margin: 8px 0;
  padding: 10px;
`;

const Icon = styled(IconButton)`
  border: 1px solid ${colors?.primary};
  color: ${colors?.primary};
  height: 35px;
  width: 35px;
  margin: 0 5px;
  padding: 0 5px;
  &:hover {
    border: 1px solid ${colors?.primary};
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

export default function List({ audiences }) {
  const navigate = useNavigate();

  return (
    <Grid container spacing={0}>
      {audiences?.map((audience, index) => (
        <Grid item xs={12} key={index}>
          <OuterCard elevation={1}>
            <Grid item alignSelf="center" paddingRight={"20px"}>
              <TextAvatar
                size={{ height: "40px", width: "40px" }}
                userName={audience?.audience_group}
              />
            </Grid>

            <Grid item xs>
              <Card container spacing={2}>
                <Grid item xs={3}>
                  <TextFourteenBlack>Audience Group</TextFourteenBlack>

                  <HeadingEighteen>{audience.audience_group}</HeadingEighteen>
                </Grid>

                {Object.keys(audience).map(
                  (aud, index) =>
                    index < 4 &&
                    aud !== "isEditable" &&
                    aud !== "audience_group" &&
                    aud !== "audience_id" && (
                      <Fragment>
                        <Grid item xs={2}>
                          <TextFourteenBlack
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {titleCase(aud.replace(/_/g, " "))}
                          </TextFourteenBlack>

                          <HeadingEighteen
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {Array.isArray(audience[aud])
                              ? audience[aud]
                                  .slice(0, 2)
                                  .map((item, index) => {
                                    return item;
                                  })
                                  .join(", ")
                              : typeof audience[aud] === "object" &&
                                !Array.isArray(audience[aud])
                              ? `${audience[aud].min} -  ${audience[aud].max}`
                              : audience[aud]}
                          </HeadingEighteen>
                        </Grid>
                      </Fragment>
                    )
                )}
              </Card>
            </Grid>

            <Grid
              item
              alignSelf={"center"}
              display={"flex"}
              justifyContent={"flex-end"}
              style={{
                width: !!audience.isEditable && "20px",
              }}
            >
              {!!audience.isEditable && (
                <Tooltip title="Edit Audience">
                  <Icon>
                    <MdEdit
                      style={{ height: "20px", width: "20px" }}
                      onClick={() =>
                        navigate(
                          pages.audienceDetails.route +
                            audience.audience_id +
                            "?mode=edit"
                        )
                      }
                    />
                  </Icon>
                </Tooltip>
              )}
            </Grid>

            <Grid
              item
              alignSelf={"center"}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Tooltip title="View Audience">
                <Icon>
                  <CgEye
                    style={{ height: "20px", width: "20px" }}
                    onClick={() =>
                      navigate(
                        pages.audienceDetails.route +
                          audience.audience_id +
                          "?mode=view"
                      )
                    }
                  />
                </Icon>
              </Tooltip>
            </Grid>
          </OuterCard>
        </Grid>
      ))}
    </Grid>
  );
}
