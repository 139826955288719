import { Fragment } from "react";
import styled from "styled-components/macro";
import { Typography, Checkbox } from "@mui/material";
import { ToggleOnOutlined, ToggleOffOutlined } from "@mui/icons-material";

import Input from "./audience fields/Input";
import Range from "./audience fields/Range";
import DropDown from "./audience fields/Dropdown";
import DatePicker from "./audience fields/DatePicker";
import AudienceName from "./audience fields/AudienceName";

const Heading = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  align-self: center;
`;

export default function FormOne({
  state,
  dispatch,
  fields,
  editState,
  formikProps,
  setButtonDisable,
}) {
  return (
    <Fragment>
      <AudienceName
        state={state}
        dispatch={dispatch}
        editState={editState}
        formikProps={formikProps}
      />

      {fields.map((field) => (
        <Fragment>
          {field.control_id === 3 && (
            <Input
              setButtonDisable={setButtonDisable}
              state={state}
              dispatch={dispatch}
              editState={editState}
              field={field}
              formikProps={formikProps}
            />
          )}

          {field.control_id === 1 && (
            <Range
              setButtonDisable={setButtonDisable}
              state={state}
              dispatch={dispatch}
              editState={editState}
              field={field}
              formikProps={formikProps}
            />
          )}

          <DropDown
            state={state}
            dispatch={dispatch}
            editState={editState}
            field={field}
            formikProps={formikProps}
          />

          {field.control_id === 4 && (
            <DatePicker
              state={state}
              dispatch={dispatch}
              editState={editState}
              field={field}
              formikProps={formikProps}
            />
          )}
        </Fragment>
      ))}
    </Fragment>
  );
}

export function Mandatory({ state, field, dispatch, editState }) {
  function handleCheckChange(value, name, key) {
    dispatch({
      type: "CALCULATE_PERCENTAGE",
      payload: { value, name, key },
    });
  }

  return (
    <div style={{ display: "flex", justifyContent: "end" }}>
      <Heading
        style={{
          color: state?.[field.name]?.mandatory ? "red" : "gray",
        }}
      >
        Mandatory
      </Heading>

      <Checkbox
        style={{ padding: "3px 9px" }}
        disabled={
          field.control_id === 1
            ? !state?.[field.name]?.value?.min ||
              !state?.[field.name]?.value?.max ||
              !editState
            : !editState ||
              !state?.[field.name]?.value ||
              state?.[field.name]?.value?.length === 0
        }
        name={`${field.name}.mandatory`}
        onChange={(e) => {
          handleCheckChange(e.target.checked, field.name, "mandatory");
        }}
        checked={state?.[field.name]?.mandatory}
        icon={<ToggleOffOutlined fontSize="large" />}
        checkedIcon={
          <ToggleOnOutlined fontSize="large" style={{ color: "red" }} />
        }
      />
    </div>
  );
}
