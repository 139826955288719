import React from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";

import { Grid, Typography } from "@mui/material";
import { PrimaryButton } from "../css components/Button";

const Wrapper = styled.div`
  height: 50%;
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function EmptyList({ emptyData, button, title, route, href, searchKey }) {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Typography component="h3" variant="h3" align="center" gutterBottom>
        {!!searchKey ? "No data found for the following search" : title}
      </Typography>

      <Grid style={{ display: "flex", gap: "5px" }}>
        {!!button && (
          <PrimaryButton
            onClick={() => navigate(route)}
            variant="contained"
            mt={2}
            href={href}
          >
            {button}
          </PrimaryButton>
        )}

        {/* {!!searchKey && (
          <PrimaryButton
            onClick={() => emptyData("")}
            variant="outlined"
            mt={2}
          >
            Back
          </PrimaryButton>
        )} */}
      </Grid>
    </Wrapper>
  );
}

export default EmptyList;
