import * as Yup from "yup";

export const onboardingObject = {
  connectionID: "",
  brandID: "",
  brandName: "",
  connectionUrl: "",
  status: "",
  token: "",
  mappedFields: [],
  fieldNames: "",
  connection: false,
  csvUpload: false,
};

export function objectFromFormData(formData, mappedFields) {
  return {
    brand_id: formData.brandID,
    connectionDetails: {
      status: formData.status,
      connection_url: formData.connectionUrl,
      token: formData.token,
      csv_upload: formData.csvUpload,
      primeIdentifiers: {
        prime_identifier: { mobile: formData.mobile },
        other_identifier: { email: formData.email, gaid: formData.gaid },
      },
    },
    fieldMappings: mappedFields,
  };
}

export function objectFromResponse(responseData) {
  return {
    status: responseData.connectionDetails?.status,
    brandDetails: responseData.brandDetails,
    fieldNames: responseData.fieldsNames || "",
    connection: responseData.connection || false,
    token: responseData.connectionDetails?.token,
    csvUpload: responseData.brandDetails?.csv_upload || false,
    brandID: responseData.connectionDetails?.brand_id,
    brandName: responseData.brandDetails?.brand_name,
    connectionID: responseData.connectionDetails?.connection_id,
    connectionUrl: responseData.connectionDetails?.connection_url,
    email: responseData.primeIdentifiers?.other_identifier?.email,
    mobile: responseData.primeIdentifiers?.prime_identifier?.mobile,
    gaid: responseData.primeIdentifiers?.other_identifier?.gaid,
  };
}

export function setValue(responseData, fieldsMapped) {
  let values = {};
  let returnValue = {};
  if (responseData.fieldMappings.length > 0) {
    for (let field of responseData.fieldMappings) {
      values[field.field_value] = {
        value: field.field_name,
        type: field.field_type,
        priority: field.field_priority,
        org_trust: field.field_org_trust,
        cpm_price: field.field_cpm_price,
      };
    }
  }

  for (let field of fieldsMapped) {
    if (values[field.field_name] !== undefined) {
      returnValue[field.field_name] = {
        value: values[field.field_name].value,
        type: values[field.field_name].type,
        priority: values[field.field_name].priority,
        org_trust: values[field.field_name].org_trust,
        cpm_price: values[field.field_name].cpm_price,
      };
    }
  }
  return returnValue;
}

export const validationSchema = Yup.object().shape({
  brandID: Yup.string().required("Brand name is required"),
  csvUpload: Yup.boolean(),
  mobile: Yup.string()
    .matches(/[a-zA-Z_]+$/gm, "It only accepts letters and underscores")
    .required("This prime identifier is required"),

  email: Yup.string().matches(
    /[a-zA-Z_]+$/gm,
    "It only accepts letters and underscores"
  ),

  gaid: Yup.string().matches(
    /[a-zA-Z_]+$/gm,
    "It only accepts letters and underscores"
  ),

  connectionUrl: Yup.string().when("csvUpload", {
    is: (value) => value === false,
    then: () =>
      Yup.string()
        .matches(
          /^((https?:\/\/)|(www.))(?:([a-zA-Z]+)|(\d+\.\d+.\d+.\d+)):\d{4}$/gm,
          "Enter correct url!"
        )
        .required("IP/Domain Url is required"),
  }),

  token: Yup.string().when("csvUpload", {
    is: (value) => value === false,
    then: () =>
      Yup.string()
        .matches(/(^[\w-]*\.[\w-]*\.[\w-]*$)/, "It must be a valid JWT token")
        .required("Port is required"),
  }),
});
