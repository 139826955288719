import { Fragment, useEffect, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Grid } from "@mui/material";

import { Mandatory } from "../FormOne";
import { TextField } from "../../../../components";

export default function DatePicker({
  dispatch,
  state,
  field,
  editState,
  formikProps,
}) {
  const [inputValue, setInputValue] = useState({});

  useEffect(() => {
    if (state[field.name]) {
      setInputValue((prev) => ({
        ...prev,
        [field.name]: state[field.name],
      }));
    }
  }, [state, field.name]);

  return (
    <Fragment>
      <Grid item xs={6}>
        <Mandatory
          dispatch={dispatch}
          state={state}
          editState={editState}
          field={field}
          formikProps={formikProps}
        />

        <DesktopDatePicker
          label={field.label}
          name={`${field.name}.value`}
          disabled={!editState}
          value={inputValue[field.name]?.value || null}
          onChange={(newValue) => {
            let date = new Date(newValue);

            if ((date.getFullYear() + "").length < 3) {
              return setInputValue({ [field.name]: { value: null } });
            }

            let d =
              date.getFullYear() +
              "-" +
              (date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1) +
              "-" +
              date.getDate();

            return setInputValue({ [field.name]: { value: d } });
          }}
          onClose={() => {
            if (inputValue[field.name]?.value) {
              handleDate(inputValue[field.name].value, field.name, "value");
            }
          }}
          renderInput={(params) => (
            <TextField
              disabled={!editState}
              name={`${field.name}.value`}
              formikProps={formikProps}
              {...params}
            />
          )}
        />
      </Grid>
    </Fragment>
  );

  function handleDate(value, name, key) {
    dispatch({
      type: "UPDATE_FIELD",
      formikProps: formikProps,
      payload: { value, name, key },
    });
  }
}
