import { Fragment, useState } from "react";
import { Verified } from "@mui/icons-material";
import { Grid, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { TextField } from "../../../components";
import {
  PrimaryButton,
  PrimaryLoadingButton,
} from "../../css components/Button";
import { useAxios } from "../../../hooks";
import VerifyOTPDialog from "./VerifyOTP";
import { colors } from "../../css components/Style";
import { alert } from "../../../redux/slices/alertSlice";

export default function FormOne({ agencyID, brandID, formikProps, editState }) {
  const axios = useAxios();
  const dispatch = useDispatch();

  const [verifyOTP, setVerifyOTP] = useState(false);
  const [loading, setLoading] = useState(false);

  // const brandID =
  //   useSelector((state) => state.memberDetails?.activeBrand?.ID) ||
  //   sessionStorage.getItem("brandID");

  function handleGST(e) {
    let initialValues = formikProps.initialValues.companyGSTNumber;
    formikProps.setValues((prev) => ({
      ...prev,
      companyGSTNumber: e.target.value,
      gstVerified: setGst(prev.companyGSTNumber),
    }));

    function setGst(value) {
      if (initialValues === e.target.value) {
        return true;
      }
      if (value !== e.target.value || initialValues !== value) {
        return false;
      }
      return true;
    }
  }

  return (
    <Fragment>
      <Grid item xs={6}>
        <TextField
          label="Company GST Number"
          name="companyGSTNumber"
          placeholder="Company GST Number"
          formikProps={formikProps}
          disabled={
            !editState ||
            (formikProps.values.is_brand_verified &&
              formikProps.values?.signAgreement)
          }
          required
          onChange={handleGST}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                {formikProps.values.gstVerified &&
                formikProps.values.companyGSTNumber ? (
                  <Verified style={{ color: colors.green }} />
                ) : (
                  <PrimaryLoadingButton
                    loading={loading}
                    onClick={verifyGST}
                    style={{ height: "30px" }}
                    size="small"
                    variant="contained"
                    disabled={
                      !editState ||
                      formikProps.values.companyGSTNumber.length !== 15
                    }
                  >
                    Verify GST
                  </PrimaryLoadingButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Company Name"
          name="companyName"
          placeholder="Company Name"
          formikProps={formikProps}
          // disabled={!editState || formikProps.values.gstVerified}
          disabled={!editState}
          required
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Company Director's Name"
          name="companyDirectorsName"
          placeholder="Company Director's Name"
          formikProps={formikProps}
          // disabled={!editState || formikProps.values.gstVerified}
          disabled={!editState}
          required
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Business Nature"
          name="companyNatureBusiness"
          placeholder="Business Nature"
          formikProps={formikProps}
          disabled={!editState}
          required
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Company Address"
          placeholder="Company Address"
          name="companyAddress"
          multiline
          // disabled={!editState || formikProps.values.gstVerified}
          disabled={!editState}
          formikProps={formikProps}
          required
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Company Contact"
          name="companyContact"
          placeholder="Company Contact"
          formikProps={formikProps}
          required
          onChange={(e) => handleNumberChange(e)}
          disabled={!editState}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                {formikProps.values.verifyNumber ? (
                  <Verified style={{ color: colors.green }} />
                ) : (
                  <PrimaryButton
                    onClick={handleVerifyNumber}
                    style={{ height: "30px" }}
                    size="small"
                    variant="contained"
                    disabled={
                      !editState ||
                      formikProps.values.companyContact?.length !== 10
                    }
                  >
                    Verify Number
                  </PrimaryButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <VerifyOTPDialog
        formikProps={formikProps}
        verifyOTP={verifyOTP}
        brandID={brandID}
        agencyID={agencyID}
        setVerifyOTP={setVerifyOTP}
      />
    </Fragment>
  );

  function handleNumberChange(e) {
    let initialValues = formikProps.initialValues?.companyContact;
    formikProps.setValues((prev) => ({
      ...prev,
      companyContact: e.target.value,
      verifyNumber: setNumber(prev.companyContact),
    }));

    function setNumber(value) {
      if (initialValues === e.target.value) {
        return true;
      }
      if (value !== e.target.value || initialValues !== value) {
        return false;
      }
      return true;
    }
  }

  function handleVerifyNumber() {
    axios({
      url: "/send/otp",
      method: "POST",
      disableRedirect: true,
      data: {
        mobile_number: formikProps.values.companyContact,
        msg_type: "registration",
        ...(!!brandID && { brand_id: parseInt(brandID) }),
        ...(!!agencyID && { agency_id: parseInt(agencyID) }),
      },
    }).then((response) => {
      if (response.status) {
        dispatch(
          alert({
            type: "success",
            message: "OTP sent successfully",
          })
        );
        setVerifyOTP(true);
      }
    });
  }

  function verifyGST() {
    setLoading(true);
    axios({
      url: "/get/gstDetail",
      method: "POST",
      disableRedirect: true,
      data: {
        gst_no: formikProps.values.companyGSTNumber,
        ...(!!brandID && { brand_id: parseInt(brandID) }),
        ...(!!agencyID && { agency_id: parseInt(agencyID) }),
      },
    }).then(function (response) {
      if (response.status) {
        formikProps.setValues((prev) => ({
          ...prev,
          gstVerified: true,
          companyNatureBusiness: response.data.nature_of_business,
          companyName: response.data.company_name,
          companyAddress: response.data.address,
          companyDirectorsName: response.data.director_name,
        }));
      }
      setLoading(false);
    });
  }
}
