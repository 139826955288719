import {
  Grid,
  Typography,
  Dialog as MUIDialog,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { DialogTitle } from "@mui/material";

import {
  CancelButton,
  PrimaryLoadingButton,
} from "../../css components/Button";
import { useAxios } from "../../../hooks";
import { TextField } from "../../../components";
import { colors } from "../../css components/Style";
import { alert } from "../../../redux/slices/alertSlice";
import { useNavigate } from "react-router-dom";
import pages from "../../../constants/pages";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "400px" },
  div: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
}));

export default function OtpVerification({
  formikProps,
  verifyOTP,
  setVerifyOTP,
  brandID,
  setStep,
  setSignLoading,
  setEditState,
}) {
  const axios = useAxios();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userID = sessionStorage.getItem("userID");

  const [loading, setLoading] = useState({
    resend: false,
    verify: false,
    send: false,
  });

  const [message, setMessage] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [aadharNumber, setAadharNumber] = useState();

  function handleChange(e) {
    if (e.target.value?.toString().length > 6) {
      return;
    }
    setOtpValue(e.target.value);
  }

  const handleClose = () => {
    setLoading({
      resend: false,
      verify: false,
      send: false,
    });
    setAadharNumber();
    setVerifyOTP(false);
  };

  function handleEnterAadharNumber(e) {
    if (e.target.value?.toString().length > 12) {
      return;
    }
    setAadharNumber(e.target.value);
  }

  return (
    <MUIDialog
      classes={{ paper: classes.paper }}
      open={verifyOTP}
      onClose={handleClose}
    >
      <DialogTitle>
        <Typography color={colors.purple} fontWeight={"500"} variant="h4">
          Sign agreement by Aadhaar OTP
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography color={colors.green} variant="subtitle1">
              Enter Aadhaar Number of Director/Signed Authority
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <TextField
              size={"small"}
              placeholder="Enter Aadhaar Number"
              name="aadharNumber"
              label="Aadhar Number"
              value={aadharNumber}
              onChange={handleEnterAadharNumber}
              type="number"
              disabled={otpSent}
              InputProps={{
                endAdornment: otpSent && (
                  <InputAdornment position={"end"}>
                    <IconButton
                      onClick={() => {
                        setOtpValue("");
                        setOtpSent(!otpSent);
                      }}
                      style={{ padding: "0" }}
                    >
                      <Edit
                        style={{
                          color: colors.purple,
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {otpSent && (
            <Fragment>
              <Grid item xs={12}>
                <Typography color={colors.green} variant="subtitle1">
                  Enter OTP sent on linked mobile number
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <TextField
                  formikProps={formikProps}
                  type="number"
                  onChange={handleChange}
                  value={otpValue}
                  name="otpValue"
                  size="small"
                  label="Enter OTP"
                  placeholder="Enter OTP"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" color={colors.error}>
                  {message}
                </Typography>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </DialogContent>

      <DialogActions style={{ padding: "24px" }}>
        <Grid container spacing={2}>
          <Grid item>
            <CancelButton
              onClick={handleClose}
              variant="outlined"
              color="primary"
            >
              Cancel
            </CancelButton>
          </Grid>

          <Grid item xs />

          {otpSent ? (
            <Fragment>
              <Grid item>
                <PrimaryLoadingButton
                  loading={loading.resend}
                  variant="outlined"
                  onClick={resendOTP}
                  color="primary"
                  autoFocus
                >
                  Resend OTP
                </PrimaryLoadingButton>
              </Grid>

              <Grid item>
                <PrimaryLoadingButton
                  disabled={otpValue.length < 6}
                  loading={loading.verify}
                  variant="contained"
                  color="primary"
                  autoFocus
                  onClick={verificationOTP}
                >
                  Verify OTP
                </PrimaryLoadingButton>
              </Grid>
            </Fragment>
          ) : (
            <Grid item>
              <PrimaryLoadingButton
                disabled={aadharNumber?.toString().length < 12 || !aadharNumber}
                loading={loading.send}
                variant="contained"
                color="primary"
                autoFocus
                onClick={handleSendOtp}
              >
                Send OTP
              </PrimaryLoadingButton>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </MUIDialog>
  );

  function handleSendOtp() {
    setLoading({ ...loading, send: true });

    axios({
      url: "/send/aadhar/otp",
      method: "POST",
      disableRedirect: true,
      data: {
        aadhar_number: parseInt(aadharNumber),
        user_id: parseInt(userID),
        msg_type: "agreement",
        brand_id: parseInt(brandID),
      },
    }).then((response) => {
      if (response.status) {
        setMessage(response.data.message);
        if (response?.data?.code === "000") {
          dispatch(
            alert({
              type: "success",
              message: response.data.message,
            })
          );
        }

        //If OTP is generated immediately for the second time ,
        //after the first successful generation

        if (response?.data?.code === "422028") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        if (response?.data?.code === "422005") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //IF WRONG AADHAAR NO IS ENTERED

        if (response?.data?.code === "422007") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //IF AADHAAR NO IS NOT LINKED WITH MOBILE NO

        if (response?.data?.code === "422008") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //HASH MISMATCH
        if (response?.data?.code === "470028") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //HASH PARAMETER NOT PROVIDED
        if (response?.data?.code === "470053") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //AADHAAR PARAMETER NOT PROVIDEd
        if (response?.data?.code === "470050") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //FLUCTUATIONS AT UIDAI SIDE
        if (response?.data?.code === "471007") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //fetching xml from uiadi end
        if (response?.data?.code === "333") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //CLIENT PARAMETER NOT PROVIDED MEAN
        if (response?.data?.code === "470046") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }
        setLoading({ ...loading, resend: false });
        setOtpSent(true);
      }
    });
  }

  function verificationOTP() {
    setLoading({ ...loading, verify: true });
    axios({
      url: "/verify/aadhar/otp",
      method: "POST",
      disableRedirect: true,
      data: {
        aadhar_number: parseInt(aadharNumber),
        user_id: parseInt(userID),
        msg_type: "agreement",
        brand_id: parseInt(brandID),
        otp: parseInt(otpValue),
      },
    }).then((response) => {
      if (response.status) {
        setMessage(response.data.message);

        if (response?.data?.code === "000") {
          formikProps.setValues((prev) => ({
            ...prev,
            verifyNumber: true,
            otp: "",
          }));
          setSignLoading({ 1: true });
          axios({
            url: "/sign/company/agreement?type=COMPANY_REGISTRATION",
            method: "POST",
            data: {
              company_id: formikProps.values?.ID,
              type: "COMPANY_REGISTRATION",
              signed: true,
            },
          }).then((response) => {
            if (response.status) {
              setStep(2);
              setEditState(false);
              formikProps.setValues((prev) => ({
                ...prev,
                viewAgreement: response.data,
                signAgreement: response.data.signed_agreement_link
                  ? true
                  : false,
              }));

              dispatch(
                alert({
                  type: "success",
                  message: "Agreement was successfully signed",
                })
              );
              navigate(pages.welcomeAsAgencyPage.route);

              setSignLoading({ 1: false });
            }
          });

          dispatch(
            alert({
              type: "success",
              message: "Aadhar Verified Successfully",
            })
          );
          setVerifyOTP(false);
        }

        //IF WRONG OTP IS ENTERED
        if (response.data?.code === "422002") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //If wrong OTP is entered for the fourth time
        if (response.data?.code === "422004") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //TXN ALREADY COMPLETED- After successful XML fetch, if the same UUID is used , the same / different OTP is used.
        if (response.data?.code === "470048") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //If wrong UUID is passed
        if (response.data?.code === "470081") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }
        if (response.data?.code === "333") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }
      }

      setLoading({ ...loading, verify: false });
    });
  }

  function resendOTP() {
    setLoading({ ...loading, resend: true });

    axios({
      url: "/send/aadhar/otp",
      method: "POST",
      disableRedirect: true,
      data: {
        aadhar_number: parseInt(aadharNumber),
        user_id: parseInt(userID),
        msg_type: "agreement",
        brand_id: parseInt(brandID),
      },
    }).then((response) => {
      if (response.status) {
        if (response?.data?.code === "000") {
          dispatch(
            alert({
              type: "success",
              message: response.data.message,
            })
          );
        }

        //If OTP is generated immediately for the second time ,
        //after the first successful generation

        if (response?.data?.code === "422028") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        if (response?.data?.code === "422005") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //IF WRONG AADHAAR NO IS ENTERED

        if (response?.data?.code === "422007") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //IF AADHAAR NO IS NOT LINKED WITH MOBILE NO

        if (response?.data?.code === "422008") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //HASH MISMATCH
        if (response?.data?.code === "470028") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //HASH PARAMETER NOT PROVIDED
        if (response?.data?.code === "470053") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //AADHAAR PARAMETER NOT PROVIDEd
        if (response?.data?.code === "470050") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //FLUCTUATIONS AT UIDAI SIDE
        if (response?.data?.code === "471007") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //fetching xml from uiadi end
        if (response?.data?.code === "333") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }

        //CLIENT PARAMETER NOT PROVIDED MEAN
        if (response?.data?.code === "470046") {
          dispatch(
            alert({
              type: "error",
              message: response.data.message,
            })
          );
        }
        setLoading({ ...loading, resend: false });

        setOtpSent(true);
      }
    });
  }
}
