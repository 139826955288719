import React, { Fragment, useEffect, useState } from "react";
import { Grid } from "@mui/material";

import { Mandatory } from "../FormOne";
import { Autocomplete } from "../../../../components";
import AutocompleteMultiSelect from "../../../../components/MultiselectAutoComplete";

function DropDown({ state, handleCheckChange, field, editState, dispatch }) {
  const [input, setInput] = useState({});

  useEffect(() => {
    if (state[field.name]) {
      setInput((prev) => ({
        ...prev,
        [field.name]: state[field.name],
      }));
    }
  }, [state, field.name]);

  return (
    <Fragment>
      {field.control_id === 2 && field.multi_select && (
        <Grid item xs={6}>
          <Mandatory
            dispatch={dispatch}
            state={state}
            editState={editState}
            field={field}
            handleCheckChange={handleCheckChange}
          />

          <AutocompleteMultiSelect
            size="small"
            forcePopupIcon={!editState ? false : true}
            label={field.label}
            name={`${field.name}.value`}
            state={state}
            value={input[field.name]?.value}
            disabled={!editState}
            defaults={{
              primaryKey: "value",
              displayLabel: "label",
            }}
            options={field.options}
            placeholder={field.placeholder}
            onChange={(_, arr, reason, detail) =>
              handleChange(
                arr,
                detail,
                reason,
                `${field.name}.value`,
                "value",
                field.options
              )
            }
            onBlur={() => {
              if (input[field.name]?.value?.length > 0) {
                handleDropDown(input[field.name].value, field.name, "value");
              }
            }}
          />
        </Grid>
      )}

      {field.control_id === 2 && !field.multi_select && (
        <Grid item xs={6}>
          <Mandatory field={field} />

          <Autocomplete
            size="small"
            label={field.label}
            forcePopupIcon={!editState ? false : true}
            name={`${field.name}.value`}
            placeholder={field.placeholder}
            value={input[field.name]?.value}
            defaults={{
              primaryKey: "value",
              displayLabel: "label",
            }}
            sx={{
              "& .MuiSvgIcon-root": {
                display: !editState && "none",
              },
            }}
            options={field.options}
            disabled={!editState}
            onChange={(_, value, type) =>
              handleChangeSingleSelect(value, type, `${field.name}`)
            }
            onBlur={() => {
              if (!!input[field.name]?.length > 0) {
                handleDropDown(input[field.name].value, field.name, "value");
              }
            }}
          />
        </Grid>
      )}
    </Fragment>
  );

  function handleDropDown(value, name, key) {
    dispatch({
      type: "MULTI_DROP_DOWN",
      payload: { value, name, key },
    });
  }

  function handleChangeSingleSelect(value, type, name) {
    function setValue(val) {
      return value.value;
    }

    if (type === "selectOption") {
      setInput((prev) => ({
        ...prev,
        [name]: { value: setValue(input[name]) },
      }));
    } else {
      value = {
        value: "",
        label: "",
      };

      setInput({ [name]: { value: setValue(input[name]) } });
    }
  }

  function handleChange(arr, { option }, reason, name, displayName, options) {
    let objectName = name.substring(0, name.indexOf("."));

    setInput((prev) => ({
      ...prev,
      [objectName]: setValuesOfDropDown(input[objectName]?.value),
    }));

    function setValuesOfDropDown(val = []) {
      let values = [...val];

      if (reason === "removeOption") {
        if (option.value) {
          values = [...values.filter((items) => items !== option?.value)];
        } else {
          values?.pop();
          option = options.filter((item) => item?.label === option)[0];
        }
      }

      if (reason === "selectOption") {
        values.push(option[displayName]);
      }

      val = {
        value: values,
      };

      return val;
    }
  }
}

export default DropDown;
