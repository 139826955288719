import { debounce } from "lodash";
import { sentenceCase } from "change-case-all";
import { Grid, Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";

import ENV from "../../../config/config";
import EmptyList from "../../misc/EmptyList";
import pages from "../../../constants/pages";
import List from "../campaign list components/List";
import { useAxios, useComponent } from "../../../hooks";
import { Loader, PageHeader } from "../../../components";
import Search from "../campaign list components/campaign consumer list components/Search";
import FloatingAddActionButtons from "../../../components/FloatingAddActionButton";
import SortBy from "../campaign list components/campaign consumer list components/SortBy";
import { getAllCampaigns } from "../create campaign components/campaign form components/campaignObject";
import { PrimaryLoadingButton } from "../../css components/Button";

export default function Campaign() {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { loader } = useComponent();

  const [categorize] = useState("");
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [lastPage, setLastPage] = useState({ totalCount: 0, lastPage: false });
  const [sortBy, setSortBy] = useState(categorize === "" ? "" : "ACTIVE");

  const { activeBrand } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    loader.start();
    let url =
      "/user/brand/getCampaignsByBrand/?brandId=" +
      activeBrand.ID +
      `&page=${page}&limit=10` +
      (sortBy ? `&campaignStatus=${sortBy}` : "") +
      (searchKey ? `&search=${encodeURIComponent(searchKey)}` : "");

    axios({
      url: url,
    }).then((response) => {
      if (response.status) {
        setCampaigns(getAllCampaigns(response, activeBrand));

        setLastPage({
          totalCount: response.data.totalCounts,
          lastPage: response.data.campaignData.length < 10,
        });

        loader.stop();
      } else {
        loader.stop();
      }
    });
  }, [
    axios,
    categorize,
    activeBrand.ID,
    loader,
    sortBy,
    activeBrand,
    searchKey,
    page,
  ]);

  const handleSearchDebounce = debounce(async (value) => {
    setSearchKey(value);
  }, 300);

  function handlePagination(step, index) {
    setPage((prev) =>
      step === "index" ? index : prev + (step === "next" ? 1 : -1)
    );
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            icons={[
              !!searchKey && campaigns.length > 0 ? (
                <Search
                  handleSearchDebounce={handleSearchDebounce}
                  searchKey={searchKey}
                  setPage={setPage}
                  setSearchKey={setSearchKey}
                />
              ) : (
                ((
                  <SortBy
                    sortBy={sortBy}
                    setPage={setPage}
                    setSortBy={setSortBy}
                  />
                ),
                (
                  <Search
                    handleSearchDebounce={handleSearchDebounce}
                    searchKey={searchKey}
                    setPage={setPage}
                    setSearchKey={setSearchKey}
                  />
                ))
              ),
            ]}
            buttons={[
              ENV !== "main" && campaigns.length > 0 && (
                <PrimaryLoadingButton
                  variant="outlined"
                  size="large"
                  loading={loading}
                  sx={{
                    marginLeft: "5px",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => handleDownloadReport()}
                >
                  Download Report
                </PrimaryLoadingButton>
              ),
            ]}
            pageHeader={"List of campaigns"}
            pageName={sentenceCase(`${sortBy.replace("_", " ")} Campaigns`)}
          />
        </Grid>
      </Grid>

      <FloatingAddActionButtons
        route={pages.createCampaign.route}
        title={"Add Campaign"}
      />

      <Loader height="75%">
        {campaigns.length > 0 ? (
          <List campaigns={campaigns} component="" />
        ) : (
          <EmptyList
            emptyData={handleSearchDebounce}
            searchKey={searchKey}
            button={"Create Campaign"}
            route={pages.createCampaign.route}
            title={"No Campaigns found."}
          />
        )}
      </Loader>

      {!!lastPage.totalCount && (
        <Grid item container display={"flex"} justifyContent={"center"}>
          <Pagination
            count={Math.ceil(lastPage.totalCount / 10)}
            variant="outlined"
            color="secondary"
            page={page}
            onChange={(e, value) => handlePagination("index", value)}
          />
        </Grid>
      )}
    </Fragment>
  );

  function handleDownloadReport() {
    setLoading(true);
    axios({
      url:
        "/brand/consolidated/report?brandId=" +
        activeBrand.ID +
        "&campaignStatus=running",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        window.location.href = response?.data?.reportUrl;
        dispatch(
          alert({
            type: "success",
            message: "Report was successfully downloaded.",
          })
        );
      }
      setLoading(false);
    });
  }
}
