import queryString from "query-string";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Loader } from "../../components";
import Form from "./create audience components/Form";
import {
  audienceObject,
  objectFromResponse,
} from "./create audience components/audienceObjects";
import { useAxios, useComponent } from "../../hooks";

export default function AudienceDetails() {
  const axios = useAxios();
  const params = useParams();
  const { loader } = useComponent();

  const audienceID = params.audienceID || "";
  const mode = queryString.parse(window.location.search).mode;

  const [fields, setFields] = useState([]);
  const [editState, setEditState] = useState(true);
  const [formData, setFormData] = useState(audienceObject);

  useEffect(() => {
    if (!!audienceID) {
      setEditState(false);
    }

    if (mode === "edit") {
      setEditState(true);
    }

    loader.start(2);
    axios({
      url: "/adtech/getAllDynamicFields",
      method: "GET",
      disableRedirect: true,
    })
      .then((response) => {
        if (response.status) {
          var fields = response.data;
          setFields(response.data);

          if (!!audienceID) {
            axios({
              url: "/brand/getDetailsOfAudience/" + audienceID,
              method: "GET",
            }).then((responses) => {
              if (responses.status) {
                let data = objectFromResponse(responses.data, fields);
                setFormData(data);
                loader.apiComplete();
                loader.apiComplete();
              }
            });
          } else {
            let data = {
              ...audienceObject,
            };

            loader.stop();
            setFormData(data);
          }
        }
      })
      .catch((err) => console.error(err));
  }, [axios, audienceID, mode, loader]);

  return (
    <Loader>
      <Form
        fields={fields}
        formData={formData}
        editState={editState}
        setEditState={setEditState}
      />
    </Loader>
  );
}
