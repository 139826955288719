import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Grid, Pagination } from "@mui/material";

import pages from "../../constants/pages";
import EmptyList from "../misc/EmptyList";
import List from "./audience list components/List";
import { useAxios, useComponent } from "../../hooks";
import Search from "./audience list components/Search";
import { Loader, PageHeader } from "../../components";
import FloatingAddActionButtons from "../../components/FloatingAddActionButton";
import { getAllAudiences } from "./create audience components/audienceObjects";

export default function AudienceList() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [audiences, setAudiences] = useState([]);
  const [lastPage, setLastPage] = useState({ totalCount: 0, lastPage: false });

  const { activeBrand } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    axios({
      url:
        "/brand/getAllAudiences?brandId=" +
        activeBrand.ID +
        `&page=${page}&limit=10` +
        (searchKey ? `&search=${encodeURIComponent(searchKey)}` : ""),
    }).then((response) => {
      if (response.status) {
        if (response.data.audienceData.length > 0) {
          setLastPage({
            totalCount: response.data.totalCounts,
            lastPage: response.data.audienceData.length < 10,
          });

          setAudiences(getAllAudiences(response));
        } else {
          setAudiences([]);
          setLastPage(true);
        }
        loader.stop();
      }
    });
  }, [axios, activeBrand.ID, loader, page, searchKey]);

  function handlePagination(step, index) {
    setPage((prev) =>
      step === "index" ? index : prev + (step === "next" ? 1 : -1)
    );
  }

  const handleSearchDebounce = debounce(async (value) => {
    setSearchKey(value);
  }, 300);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            pageName={"My Audiences"}
            pageHeader="List of Audiences"
            icons={[
              ((!!searchKey && audiences.length === 0) ||
                audiences.length > 0) && (
                <Search
                  handleSearchDebounce={handleSearchDebounce}
                  searchKey={searchKey}
                  setPage={setPage}
                  setSearchKey={setSearchKey}
                />
              ),
            ]}
          />
        </Grid>
      </Grid>

      <FloatingAddActionButtons
        route={pages.createAudience.route}
        title={"Add Audience"}
      />

      <Loader height="75%">
        {audiences.length > 0 ? (
          <List audiences={audiences} />
        ) : (
          <EmptyList
            emptyRoute={pages.audienceList.route}
            searchKey={searchKey}
            title={"No audience found."}
            button={"Create Audience"}
            route={pages.createAudience.route}
          />
        )}
      </Loader>

      {!!lastPage.totalCount && (
        <Grid item container display={"flex"} justifyContent={"center"}>
          <Pagination
            count={Math.ceil(lastPage.totalCount / 10)}
            variant="outlined"
            color="secondary"
            page={page}
            onChange={(e, value) => handlePagination("index", value)}
          />
        </Grid>
      )}
    </Fragment>
  );
}
