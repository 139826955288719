import React, { Fragment } from "react";
import { get } from "lodash";
import styled from "styled-components/macro";
import { CgAsterisk } from "react-icons/cg";
import { Avatar, Grid, Typography } from "@mui/material";

import { uploadToS3 } from "../../../utils";
import {
  AutocompleteMultiSelect,
  ComponentHeader,
  TextField,
} from "../../../components";
import { ErrorButton } from "../../css components/Button";
import { colors } from "../../css components/Style";
import { useSelector } from "react-redux";

const ImageAvatar = styled(Avatar)`
  height: 130px;
  width: 130px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

const AllowedImage = styled(Typography)`
  color: #333;
  font-size: 15px;
  opacity: 0.8;
  margin-top: 15px;
`;

export default function FormOne({ list, formikProps, editState, multiBrand }) {
  const agencyID =
    useSelector((state) => state.memberDetails.agencyID) ||
    sessionStorage.getItem("agencyID");

  console.log(formikProps);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <ComponentHeader title={"Brand Details"} subtitle={""} />
      </Grid>

      <Grid item textAlign="center" display="flex">
        <ImageAvatar src={formikProps.values.brandAvatar} />
      </Grid>

      {editState && (
        <Grid item alignSelf={"center"}>
          <div style={{ display: "flex" }}>
            <input
              disabled={!editState}
              accept=".jpg,.jpeg,.png"
              style={{ display: "none" }}
              id="avatar-button-file"
              multiple
              onChange={(event) => handleFileChange(event, "brandAvatar")}
              type="file"
            />
            <label htmlFor="avatar-button-file">
              <div
                className="add-new-logo"
                variant="contained"
                style={{ marginRight: "10px", backgroundColor: colors.purple }}
              >
                Add New Logo
              </div>
            </label>

            {formikProps.values.brandAvatar && (
              <ErrorButton
                variant="outlined"
                onClick={() => {
                  formikProps.setValues((prev) => ({
                    ...prev,
                    brandAvatar: "",
                  }));
                }}
              >
                Reset
              </ErrorButton>
            )}
          </div>

          <AllowedImage>
            Allowed JPG, GIF or PNG
            {!formikProps.values.brandAvatar && !agencyID && (
              <CgAsterisk style={{ color: colors.error }} />
            )}
            .
          </AllowedImage>
        </Grid>
      )}

      <Grid item xs={12}></Grid>

      <Grid item xs={4}>
        <TextField
          label="Brand Name"
          disabled={!editState}
          formikProps={formikProps}
          placeholder="Brand Name"
          name="brandName"
          required
          inputProps={{ maxLength: 35 }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="Website"
          disabled={!editState}
          formikProps={formikProps}
          name="website"
          placeholder="Website"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      {!agencyID && (
        <Grid item xs={4}>
          <TextField
            label="Online Store"
            disabled={!editState}
            formikProps={formikProps}
            name="onlineStore"
            placeholder="Online Store"
          />
        </Grid>
      )}

      {agencyID && (
        <Fragment>
          <Grid item xs={4}>
            <AutocompleteMultiSelect
              required={agencyID ? false : true}
              name="siteCategory"
              label={"Business Category"}
              disabled={!editState}
              forcePopupIcon={!editState ? false : true}
              formikProps={formikProps}
              defaults={{
                primaryKey: "name",
                displayLabel: "name",
              }}
              options={list?.categories}
              placeholder="Select Category"
              onChange={(_, arr, reason, option) => {
                handleChange(arr, option, reason, "siteCategory", "name");
              }}
            />
          </Grid>

          {formikProps.values.siteCategory.includes("Others") && (
            <Fragment>
              <Grid item xs={6}>
                <TextField
                  required
                  disabled={!editState}
                  formikProps={formikProps}
                  name="others_category"
                  label={"Enter your other category"}
                  placeholder={"Enter your other category"}
                />
              </Grid>
            </Fragment>
          )}
        </Fragment>
      )}

      <Grid item xs={12}>
        <TextField
          label="About"
          disabled={!editState}
          formikProps={formikProps}
          placeholder="About"
          name="about"
          rows={3}
          required
          multiline
        />
      </Grid>

      {!agencyID && (
        <Grid item xs={6}>
          <TextField
            label="Monthly Active Users"
            disabled={!editState}
            formikProps={formikProps}
            name="monthlyActiveUsers"
            placeholder="Monthly Active Users"
            inputProps={{ type: "number" }}
          />
        </Grid>
      )}
    </React.Fragment>
  );

  function handleChange(arr, { option }, reason, name, displayName) {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [name]: setValuesOfDropDown(prevVal[name]),
    }));

    function setValuesOfDropDown(val) {
      let values = [...val];

      if (reason === "removeOption") {
        const nameToDropDown = {
          audienceLocation: "country_code",
          siteCategory: "name",
          audienceLanguages: "code",
        };

        let dropDownName = nameToDropDown[name];

        if (option[dropDownName]) {
          values = [
            ...values.filter((items) => items !== option[dropDownName]),
          ];
        } else {
          values.pop();
        }
      }
      if (reason === "selectOption") {
        values.push(option[displayName]);
      }

      return values;
    }
  }

  function handleFileChange({ target }, name) {
    let folderPath = "company_document/" + new Date().getTime() + "_";
    const file = get(target, "files[0]", "");
    let fileName = file?.name?.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "");
    const uploadBucket = {
      name: "adn-media",
      region: "ap-south-1",
      identityPoolID: "ap-south-1:16fa1fcf-7cdd-4593-9ee0-6f621526defc",
    };

    uploadToS3({
      bucket: uploadBucket,
      filePath: folderPath + fileName,
      file,
    }).then((response) => {
      if (response.status) {
        formikProps.setFieldValue(
          [name],
          "https://" +
            uploadBucket.name +
            ".s3.ap-south-1.amazonaws.com/" +
            folderPath +
            fileName
        );
      }
    });
  }
}
