import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Paper, InputBase, IconButton, Grid } from "@mui/material";

export default function Search({
  handleSearchDebounce,
  setPage,
  setSearchKey,
}) {
  return (
    <Paper
      component="form"
      elevation={0}
      sx={{
        p: "15px 18px",
        display: "flex",
        alignItems: "center",
        width: "inherit",
        height: "50px",
        borderRadius: "10px",
        justifyContent: "space-between",
      }}
    >
      <InputBase
        sx={{
          ml: 1,
          flex: 1,
          color: "rgba(128, 128, 128, 0.7)",
        }}
        placeholder="Search "
        onChange={(e) => {
          setPage(1);

          handleSearchDebounce(e.target.value);
        }}
      />

      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon style={{ color: "#231F20" }} />
      </IconButton>
    </Paper>
  );
}
