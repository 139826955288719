import * as Yup from "yup";

export const audienceObject = {
  ID: "",
  brandID: "",
  additionalFields: [],
  percentage: {
    value: 0,
  },
  isEditable: true,
  audience_group: "",
  mandatoryTrueCount: "",
};

export function objectFromResponse(response, fields) {
  let data = {};
  data = {
    ...audienceObject,
  };

  let additionalFields = [];
  for (let key of response.fields) {
    if (!key?.is_additional) {
      if (
        Array.isArray(Object.values(key)[0]) &&
        Object.values(key)[0]?.length > 0
      ) {
        data[Object.keys(key)[0]] = {
          value: Object.values(key)[0]?.length > 0 ? Object.values(key)[0] : [],
          mandatory: Object.values(key)[1] || false,
          userCount: Object.values(key)[3] || 0,
        };
      }

      if (
        typeof Object.values(key)[0] === "string" &&
        !!Object.values(key)[0]
      ) {
        data[Object.keys(key)[0]] = {
          value: !!Object.values(key)[0] ? Object.values(key)[0] : "",
          mandatory: Object.values(key)[1] || false,
          userCount: Object.values(key)[3] || 0,
        };
      }

      if (
        typeof Object.values(key)[0] === "object" &&
        !Array.isArray(Object.values(key)[0]) &&
        key[Object.keys(key)[0]]
      ) {
        data[Object.keys(key)[0]] = {
          value: key[Object.keys(key)[0]],
          mandatory: Object.values(key)[1] || false,
          userCount: Object.values(key)[3] || 0,
        };
      }

      if (
        typeof Object.values(key)[0] === "number" &&
        Object.values(key)[0] !== 0
      ) {
        data[Object.keys(key)[0]] = {
          value: Object.values(key)[0] !== 0 ? Object.values(key)[0] : 0,
          mandatory: Object.values(key)[1] || false,
          userCount: Object.values(key)[3] || 0,
        };
      }
    } else {
      additionalFields.push({
        key: Object.keys(key)[0],
        value: Object.values(key)[0],
        mandatory: key.mandatory,
      });
    }
  }

  return {
    ...data,
    percentage: data.percentage,
    ID: response.audience_id,
    brandID: response.brand_id,
    audience_group: data.audience_group,
    additionalFields: additionalFields,
    isEditable: response.is_editable,
  };
}

export function objectFromFormData(formData, details) {
  let fields = [];

  for (let field in formData) {
    if (
      field !== "ID" &&
      field !== "brandID" &&
      field !== "isEditable" &&
      field !== "additionalFields" &&
      field !== "dirty" &&
      field !== "mandatoryTrueCount"
    ) {
      if (formData[field]?.value === null) {
        fields.push({
          [field]: {
            value: "",
            mandatory: false,
          },
        });
      } else {
        fields.push({ [field]: formData[field] });
      }
    }
  }

  for (let addedFields of formData.additionalFields) {
    fields.push({
      [addedFields.key]: {
        value: addedFields.value,
        mandatory: addedFields.mandatory || false,
        is_additional: true,
      },
    });
  }

  return {
    brand_id: parseInt(details.activeBrand.ID),
    fields: fields,
  };
}

export function getAllAudiences(response) {
  let allAudiences = [];
  for (let key of response.data.audienceData) {
    let data = {};
    for (let fields of key.fields) {
      if (Array.isArray(Object.values(fields)[0])) {
        if (Object.values(fields)[0].length > 0) {
          data[Object.keys(fields)[0]] =
            Object.values(fields)[0].length > 0 ? Object.values(fields)[0] : [];
        }
      }

      if (typeof Object.values(fields)[0] === "string") {
        if (!!Object.values(fields)[0]) {
          data[Object.keys(fields)[0]] = !!Object.values(fields)[0]
            ? Object.values(fields)[0]
            : "";
        }
      }

      if (
        typeof Object.values(fields)[0] === "object" &&
        !Array.isArray(Object.values(fields)[0])
      ) {
        if (Object.keys(key)[0].length > 0) {
          data[Object.keys(fields)[0]] =
            Object.keys(key)[0].length > 0 ? Object.values(fields)[0] : {};
        }
      }
    }
    allAudiences.push({
      ...data,
      isEditable: key.is_editable,
      audience_id: key.audience_id,
    });
  }
  return allAudiences;
}

// export function schema(fields) {
//   var validationSchema = Yup.object().shape({
//     additionalFields: Yup.array().of(
//       Yup.object().shape({
//         key: Yup.string().test(
//           "Is-welcome", //The name of the test
//           "Wrong value supplied, please enter 'Welcome'!", // The error message to show
//           (value) => console.log(value, "key") // The test logic
//         ),
//         value: Yup.string().test(
//           "Is-welcome", //The name of the test
//           "Wrong value supplied, please enter 'Welcome'!", // The error message to show
//           (value) => console.log(value) // The test logic
//         ),
//       })
//     ),
//     audience_group: Yup.object().shape({
//       value: Yup.string()
//         .min(3, "Greater than 3 letters")
//         .required("Audience Group is required"),
//     }),
//     mandatoryTrueCount: Yup.string().matches(
//       /^[1-9]+$/,
//       "One Field Need To Be Mandatory True"
//     ),
//   });

//   for (let field of fields) {
//     if (field.control_id === 1) {
//       validationSchema = validationSchema.concat(
//         Yup.object({
//           [field.name]: Yup.object().shape({
//             value: Yup.object().shape({
//               min: Yup.number()
//                 .nullable()
//                 .moreThan(
//                   field.min_default_val - 1,
//                   field.label +
//                     " should be greater than " +
//                     field.min_default_val
//                 )
//                 .lessThan(
//                   field.max_default_val + 1,
//                   field.label + " should be less than " + field.max_default_val
//                 ),
//               max: Yup.number().when("min", {
//                 is: (val) => val,
//                 then: () =>
//                   Yup.number()
//                     .nullable()
//                     .lessThan(
//                       field.max_default_val + 1,
//                       field.label +
//                         " should be less than " +
//                         field.max_default_val
//                     )
//                     .moreThan(Yup.ref("min"), "Max  should be greater")
//                     .required(field.label + " is required"),
//               }),
//             }),
//           }),
//         })
//       );
//     }
//   }

//   return validationSchema;
// }
